<template>
  <div class="user_list">
    <el-form :inline="true" :model="search" class="demo-form-inline" size="small">
      <el-form-item label="用户名">
        <el-input v-model="search.name" placeholder="用户昵称"></el-input>
      </el-form-item>
      <el-form-item label="电话">
        <el-input v-model="search.phone" placeholder="用户电话"></el-input>
      </el-form-item>
      <el-form-item label="注册时间">
        <el-date-picker
          v-model="search.signtime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" style="width: 100%" border size="mini">
      <el-table-column prop="id" label="ID" width="100" fixed="left"></el-table-column>
      <el-table-column
        prop="avatar"
        label="头像">
        <template slot-scope="scope">
          <div class="avatar-wrap">
            <el-avatar shape="square" :size="30" :src="scope.row.avatar"></el-avatar>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="nickname"
        label="用户名">
      </el-table-column>
      <el-table-column
        prop="phone"
        label="手机号">
      </el-table-column>
      <el-table-column
        prop="sign_time"
        label="注册时间">
      </el-table-column>
      <el-table-column
        prop="login_time"
        label="最近登录">
      </el-table-column>
      <el-table-column
        prop="mark"
        label="备注">
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="150">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleMark(scope.$index, scope.row)">备注</el-button>
          <el-button size="mini" type="success" plain @click="handleInfo(scope.$index, scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="100">
    </el-pagination>
    <el-dialog
      title="订单详情"
      :visible.sync="dialogInfoVisible"
      width="30%"
      top="10vh"
      :before-close="handleInfoClose">
        <el-row class="info-row">
          <el-col>订单编号：</el-col>
          <el-col>产品标题：</el-col>
          <el-col>产品规格：</el-col>
          <el-col>购买数量：</el-col>
          <el-col>采购人：</el-col>
          <el-col>下单时间：</el-col>
          <el-col>收货人：</el-col>
          <el-col>联系电话：</el-col>
          <el-col>收货地址：</el-col>
          <el-col>备注：</el-col>
        </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogInfoVisible = false" size="mini">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="添加备注"
      :visible.sync="dialogMarkVisible"
      width="30%"
      :before-close="handleMarkClose">
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入备注"
        v-model="mark">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogMarkVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogMarkVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Order_list',
  data () {
    return {
      mark: '',
      dialogMarkVisible: false,
      dialogInfoVisible: false,
      search: {},
      tableData: [{}]
    }
  },
  methods: {
    handleMark () {
      this.dialogMarkVisible = true
      console.log('mark')
    },
    handleMarkClose () {
      console.log('markclose')
    },
    handleInfo () {
      this.dialogInfoVisible = true
    },
    handleInfoClose () {
      this.dialogInfoVisible = false
      console.log('handleInfoClose')
    },
    onSearch () {
      console.log('search')
    }
  }
}
</script>

<style scoped>
.info-row div{
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e9e9e9;
}
.avatar-wrap{
  max-width: 30px;
  max-height: 30px;
  overflow: hidden;
}
</style>
